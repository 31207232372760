import React from 'react'
import Svg from './scene.svg'
import sceneTransitions1 from './transitions'
import { getTransitionElements } from './transition-utilities'
import onTick from './tickFunction'

const getTicker = observer => {
  document.querySelectorAll('[data-scene-placeholder]').forEach(placeholder => {
    observer.observe(placeholder)
  })
}

const createThreshold = height => {
  const count = window.Math.ceil(height / 1)
  const t = []
  const ratio = 1 / count
  for (let i = 0; i < count; i += 1) {
    t.push(i * ratio)
  }
  return t
}

export default function scene (props) {
  React.useEffect(() => {
    const transitionsData = sceneTransitions1.transitions(props.isPortrait)
    const transitionElements = getTransitionElements(transitionsData)

    const observer = new IntersectionObserver(
      onTick.bind(null, transitionsData, transitionElements),
      {
        threshold: createThreshold(
          sceneTransitions1.duration + window.innerHeight
        )
      }
    )

    getTicker(observer)

    return () => {
      observer.disconnect()
    }
  })

  React.useEffect(() => {
    document.querySelector('#layer2').addEventListener('click', () => {
      window.open('https://www.linkedin.com/in/nitin-sudhakar/')
    })
  })
  React.useEffect(() => {
    document.querySelector('#layer3').addEventListener('click', () => {
      window.open('https://www.facebook.com/profile.nitin')
    })
  })
  React.useEffect(() => {
    document.querySelector('#g9740').addEventListener('click', () => {
      window.open('https://t.me/nitinsudhakar')
    })
  })

  React.useEffect(() => {
    document.querySelector('#layer4').addEventListener('click', () => {
      window.open('https://twitter.com/nitin_sudhakar_')
    })
  })
  React.useEffect(() => {
    document.querySelector('#text7325').addEventListener('click', () => {
      window.open('https://rzp.io/l/elzabrowser')
    })
  })
  React.useEffect(() => {
    document.querySelector('#text7321').addEventListener('click', () => {
      window.open('https://www.linkedin.com/in/nitin-sudhakar/')
    })
  })
  React.useEffect(() => {
    document.querySelector('#contactsbutton').addEventListener('click', () => {
      window.open('https://www.calendar.com/nitinsudhakar/let\'s-talk!/duration/')
    })
  })
  //https://medium.com/@nitin.sudhakar
  //https://www.instagram.com/nitin.sudhakar/
  return <Svg width={props.width} height={props.height} />
}
